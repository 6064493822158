import { Injectable } from '@angular/core';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { Action } from '../../../store/action.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserActions } from '../../user/redux/user.actions';
import { FeatureActions } from './feature.actions';
import { FeatureService } from '../feature.service';
import { of } from 'rxjs';

@Injectable()
export class FeatureEpic {
  constructor (
    private featureActions: FeatureActions,
    private featureService: FeatureService,
  ) { }

  getEpics = () => combineEpics( this.getFeaturesEpic.bind( this ) );

  getFeaturesEpic ( action$: ActionsObservable<Action<any>> ) {
    return action$
        .pipe(
            ofType(UserActions.SET_USER_AND_PRINCIPAL_DATA),
            switchMap(() => this.featureService.getFeatures().pipe(
                map(this.featureActions.setFeaturesAction),
                catchError((error: any) => of(this.featureActions.setErrorAction(error)))
            ))
        );
  }
}
