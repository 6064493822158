import { MigrationState } from './migration.model';
import { Action } from '../../../store/action.model';
import { MigrationActions } from './migration.actions';

const INITIAL_STATE: MigrationState = {
  showMigrationBanner: false,
  jivePortalSunsetDate: undefined
};

export function migrationReducer (
  lastState: MigrationState = INITIAL_STATE,
  action: Action<Partial<MigrationState>>
): MigrationState {
  switch ( action.type ) {

    case MigrationActions.SET_MIGRATION_STATE:
      return {
        ...lastState,
        showMigrationBanner: action.payload.showMigrationBanner,
        jivePortalSunsetDate: action.payload.jivePortalSunsetDate
      };
    default:
      return lastState;
  }
}
