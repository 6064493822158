<bp-migration-banner/>
<div class="billing-details" role="main">
  <div class="page-title" [attr.aria-hidden]="(modalVisible$|async)">
    <div class="page-title-text"
      [innerHTML]="'PAGE_TITLE_BILLING_DETAILS'| translate"
      role="heading"
      aria-level="1">
      <!-- Invoices -->
    </div>
    <bp-selected-organization></bp-selected-organization>
  </div>
  <bp-current-balance></bp-current-balance>

  <bp-invoice-list [attr.aria-hidden]="(modalVisible$|async)"></bp-invoice-list>
</div>

