import { Injectable } from '@angular/core';
import { UserEpic } from '../services/user/redux/user.epic';
import { AuthEpic } from '../services/auth/redux/auth.epic';
import { OrganizationEpic } from '../services/organization/redux/organization.epic';
import { OrganizationListEpic } from '../services/organization-list/redux/organization-list.epic';
import { PaymentMethodEpic } from '../services/payment-method/redux/payment-method.epic';
import { InvoiceEpic } from '../services/invoice/redux/invoice.epic';
import { AutopayEpic } from '../services/autopay/redux/autopay.epic';
import { ReceiptEmailEpic } from '../services/receipt-email/redux/receipt-email.epic';
import { PaymentEpic } from '../services/payment/redux/payment.epic';
import { InvoiceListEpic } from '../services/invoice-list/redux/invoice-list.epic';
import { BillingEmailEpic } from '../services/billing-email/redux/billing-email.epic';
import { FlexFormEpic } from '../services/flex-form/redux/flex-form.epic';
import { combineEpics } from 'redux-observable';
import { CallDetailsEpic } from '../services/call-details/redux/call-details.epic';
import { SmsDetailsEpic } from '../services/sms-details/redux/sms-details.epic';
import { CopasEpic } from '../services/copas/redux/copas.epic';
import { CardinalEpic } from '../services/cardinal/redux/cardinal.epic';
import { FeatureEpic } from '../services/feature/redux/feature.epic';
import { MessageEpic } from '../services/message/redux/message.epic';
import { MigrationEpic } from '../services/migration/redux/migration.epic';

@Injectable()
export class RootEpic {
  constructor (
    private userEpic: UserEpic,
    private authEpic: AuthEpic,
    private featureEpic: FeatureEpic,
    private messageEpic: MessageEpic,
    private organizationEpic: OrganizationEpic,
    private organizationListEpic: OrganizationListEpic,
    private paymentMethodEpic: PaymentMethodEpic,
    private callDetailsEpic: CallDetailsEpic,
    private invoiceEpic: InvoiceEpic,
    private invoiceListEpic: InvoiceListEpic,
    private autopayEpic: AutopayEpic,
    private receiptEmailEpic: ReceiptEmailEpic,
    private paymentEpic: PaymentEpic,
    private billingEmailEpic: BillingEmailEpic,
    private flexFormEpic: FlexFormEpic,
    private copasEpic: CopasEpic,
    private cardinalEpic: CardinalEpic,
    private smsDetailsEpic: SmsDetailsEpic,
    private migrationEpic: MigrationEpic ) { }

  getEpicList () {
    return combineEpics(
      this.userEpic.getEpics(),
      this.authEpic.getEpics(),
      this.featureEpic.getEpics(),
      this.messageEpic.getEpics(),
      this.organizationEpic.getEpics(),
      this.organizationListEpic.getEpics(),
      this.paymentMethodEpic.getEpics(),
      this.callDetailsEpic.getEpics(),
      this.invoiceEpic.getEpics(),
      this.invoiceListEpic.getEpics(),
      this.autopayEpic.getEpics(),
      this.receiptEmailEpic.getEpics(),
      this.paymentEpic.getEpics(),
      this.billingEmailEpic.getEpics(),
      this.flexFormEpic.getEpics(),
      this.copasEpic.getEpics(),
      this.cardinalEpic.getEpics(),
      this.smsDetailsEpic.getEpics(),
      this.migrationEpic.getEpics()
    );
  }
}
