import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MigrationEpic } from './redux/migration.epic';
import { MigrationActions } from './redux/migration.actions';
import { MigrationService } from './migration.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    MigrationActions,
    MigrationEpic,
    MigrationService
  ],
  declarations: []
} )
export class MigrationModule {}
