import { combineReducers } from 'redux';
import { authReducer } from '../services/auth/redux/auth.reducer';
import { userReducer } from '../services/user/redux/user.reducer';
import { AppState } from './app-state.model';
import { organizationReducer } from '../services/organization/redux/organization.reducer';
import { organizationListReducer } from '../services/organization-list/redux/organization-list.reducer';
import { mobileReducer } from '../services/mobile/redux/mobile.reducer';
import { paymentMethodReducer } from '../services/payment-method/redux/payment-method.reducer';
import { invoiceReducer } from '../services/invoice/redux/invoice.reducer';
import { autopayReducer } from '../services/autopay/redux/autopay.reducer';
import { receiptEmailReducer } from '../services/receipt-email/redux/receipt-email.reducer';
import { paymentReducer } from '../services/payment/redux/payment.reducer';
import { invoiceListReducer } from '../services/invoice-list/redux/invoice-list.reducer';
import { languageSelectorReducer } from '../services/language-selector/redux/language-selector.reducer';
import { billingEmailReducer } from '../services/billing-email/redux/billing-email.reducer';
import { flexFormReducer } from '../services/flex-form/redux/flex-form.reducer';
import { callDetailsReducer } from '../services/call-details/redux/call-details.reducer';
import { smsDetailsReducer } from '../services/sms-details/redux/sms-details.reducer';
import { copasReducer } from '../services/copas/redux/copas.reducer';
import { modalReducer } from '../services/modal/redux/modal.reducer';
import { cardinalReducer } from '../services/cardinal/redux/cardinal.reducer';
import { ccGeneratorReducer } from '../services/cc-generator/redux/cc-generator.reducer';
import { featureReducer } from '../services/feature/redux/feature.reducer';
import { messageReducer } from '../services/message/redux/message.reducer';
import { migrationReducer } from '../services/migration/redux/migration.reducer';

export const rootReducer = combineReducers<AppState>( {
  auth: authReducer,
  feature: featureReducer,
  message: messageReducer,
  user: userReducer,
  organization: organizationReducer,
  organizationList: organizationListReducer,
  mobile: mobileReducer,
  paymentMethod: paymentMethodReducer,
  callDetails: callDetailsReducer,
  smsDetails: smsDetailsReducer,
  invoice: invoiceReducer,
  invoiceList: invoiceListReducer,
  autopay: autopayReducer,
  receiptEmail: receiptEmailReducer,
  payment: paymentReducer,
  language: languageSelectorReducer,
  billingEmail: billingEmailReducer,
  flexForm: flexFormReducer,
  copas: copasReducer,
  modal: modalReducer,
  cardinal: cardinalReducer,
  ccGenerator: ccGeneratorReducer,
  migration: migrationReducer,
} );
