import { NgModule } from '@angular/core';
import { AuthModule } from './auth/auth.module';
import { UserModule } from './user/user.module';
import { OrganizationModule } from './organization/organization.module';
import { OrganizationListModule } from './organization-list/organization-list.module';
import { PaymentMethodModule } from './payment-method/payment-method.module';
import { CallDetailsModule } from './call-details/call-details.module';
import { InvoiceModule } from './invoice/invoice.module';
import { AutopayModule } from './autopay/autopay.module';
import { InvoiceListModule } from './invoice-list/invoice-list.module';
import { LanguageSelectorModule } from './language-selector/language-selector.module';
import { BillingEmailModule } from './billing-email/billing-email.module';
import { MobileModule } from './mobile/mobile.module';
import { PaymentModule } from './payment/payment.module';
import { ReceiptEmailModule } from './receipt-email/receipt-email.module';
import { CopasModule } from './copas/copas.module';
import { ModalModule } from './modal/modal.module';
import { CardinalModule } from './cardinal/cardinal.module';
import { CcGeneratorModule } from './cc-generator/cc-generator.module';
import { FeatureModule } from './feature/feature.module';
import { MessageModule } from './message/message.module';
import { SmsDetailsModule } from './sms-details/sms-details.module';
import { MigrationModule } from './migration/migration.module';

@NgModule( {
  imports: [
    AuthModule,
    UserModule,
    FeatureModule,
    MessageModule,
    OrganizationModule,
    OrganizationListModule,
    PaymentMethodModule,
    CallDetailsModule,
    SmsDetailsModule,
    InvoiceModule,
    AutopayModule,
    InvoiceListModule,
    LanguageSelectorModule,
    BillingEmailModule,
    MobileModule,
    PaymentModule,
    ReceiptEmailModule,
    CopasModule,
    ModalModule,
    CardinalModule,
    CcGeneratorModule,
    MigrationModule
  ],
  declarations: []
} )
export class ServicesModule {}
