import { Injectable } from '@angular/core';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { Action } from '../../../store/action.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MigrationService } from '../migration.service';
import { MigrationActions } from './migration.actions';

@Injectable()
export class MigrationEpic {
  constructor (
    private migrationService: MigrationService,
    private migrationActions: MigrationActions,
  ) { }

  getEpics () {
    return combineEpics(
      this.getAndSetMigrationState.bind( this ),
    );
  }

  getAndSetMigrationState ( action$: ActionsObservable<Action<any>> ) {
    return action$
      .pipe(
        ofType( MigrationActions.GET_MIGRATION_STATE ),
        switchMap( ( { payload: { orgId } } ) => this.migrationService.getGtcMigrationState( orgId )
          .pipe(
            map( ( newState => {
              return this.migrationActions.setMigrationState( newState );
            } ) ),
            catchError( ( err ) => {
                return of( this.migrationActions.setErrorAction( err ) );
              }
            )
          )
        )
      );
  }
}
