<bp-migration-banner/>
<div class="call-details" role="main">
  <div class="page-title" role="heading" aria-level="1">
    <div class="page-title-text"
      [innerHTML]="'CALL_DETAILS_TITLE'| translate">
      <!-- Call Details -->
    </div>
    <bp-selected-organization></bp-selected-organization>
  </div>

  <div class="component-title call-details-month-selection-title"
    [innerHTML]="'MONTH_SELECTION_TITLE'| translate"
    role="heading"
    aria-level="2">
    <!-- Search and download monthly billed call details -->
  </div>

  <div class="call-details-date-form">
    <div *ngIf="showAccountSelector" class="call-details-account-selector">
      <div class="call-details-form-label" [innerHTML]="'CALL_DETAILS_SELECT_ACCOUNT'| translate"></div>
      <form class="org-selector" role="search" [formGroup]="organizationForm">
        <mat-select
          role="listbox"
          [aria-label]="'CALL_DETAILS_SELECT_ACCOUNT_LABEL' | translate"
          formControlName="organization"
          class="call-details-form-input medium call-details-form-select">
          <mat-option
            role="option"
            *ngFor="let account of accountOptions"
            [value]="account.id"
            [attr.aria-label]="account.name">
            {{account.name}}
          </mat-option>
        </mat-select>
      </form>
    </div>

    <form class="form--card" [formGroup]="form" (ngSubmit)="downloadFile()">

      <mat-label class="call-details-form-label" [innerHTML]="'DETAILS_PERIOD'| translate">
        <!-- Select a date -->
      </mat-label>

      <div class="call-details-details-period">
        <mat-select
          role="listbox"
          [aria-label]="'MONTH' | translate"
          formControlName="month"
          class="call-details-form-input medium call-details-form-select"
          placeholder="{{ 'MONTH' | translate }}">
          <mat-option
            role="option"
            *ngFor="let month of availableMonths"
            [value]="month"
            [attr.aria-label]="month | translate">
            {{month | translate}}
          </mat-option>
        </mat-select>

        <mat-select
          [attr.aria-label]="'YEAR' | translate"
          formControlName="year"
          class="call-details-form-input medium call-details-form-select"
          placeholder="{{ 'YEAR' | translate }}">
          <mat-option
            *ngFor="let year of availableYears"
            [value]="year"
            [attr.aria-label]="year">
            {{year}}
          </mat-option>
        </mat-select>

        <button [disabled]="!isFormValid() || loading"
          class="jive-button jive-medium jive-primary call-details-download-button"
          [attr.aria-label]="downloadButtonKey | translate"
          type="submit">
          <!-- Download -->
          {{downloadButtonKey | translate}}
          <bp-ellipsis-animation *ngIf="getInProgress"></bp-ellipsis-animation>
        </button>
      </div>
    </form>
  </div>

  <div class="call-details-error-modal bp-modal" *ngIf="errorMessageKey">
    <div class="call-details-error-modal-content bp-modal-content">
      <div class="component-title" [innerHTML]="'ERROR'| translate">
        <!-- Error -->
      </div>
      <div class="call-details-error-modal-message" [innerHTML]="errorMessageKey| translate">
      </div>
      <div class="call-details-error-modal-action">
        <button (click)="closeErrorModal()" class="jive-button jive-small jive-primary" [innerHTML]="'CLOSE'| translate"
          type="reset">
          <!-- close -->
        </button>
      </div>
    </div>
  </div>

</div>
