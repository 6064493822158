import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './content/content.component';
import { JiveNavbarRightSideComponent } from './navbar-right-side/navbar-right-side.component';
import { AppMaterialModule } from '../material/app-material.module';
import { RouterModule } from '@angular/router';
import { ErrorsModule } from './errors/errors.module';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { CurrentBalanceComponent } from './current-balance/current-balance.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OrganizationModule } from '../services/organization/organization.module';
import { OrganizationSelectorComponent } from './organization-selector/organization-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';
import { SelectedOrganizationComponent } from './selected-organization/selected-organization.component';
import { PayTotalComponent } from './pay-total/pay-total.component';
import { PaymentMethodSelectorComponent } from './payment-method-selector/payment-method-selector.component';
import { PaymentMethodGeneratorComponent } from './payment-method-generator/payment-method-generator.component';
import { AutopayComponent } from './autopay/autopay.component';
import { ReceiptEmailComponent } from './receipt-email/receipt-email.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { PayNowComponent } from './pay-now/pay-now.component';
import { PayAmountSelectorComponent } from './pay-amount-selector/pay-amount-selector.component';
import { PaymentMethodListComponent } from './payment-method-list/payment-method-list.component';
import { PbxSelectComponent } from './pbx-select/pbx-select.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { BillingEmailComponent } from './billing-email/billing-email.component';
import { DataErrorComponent } from './data-error/data-error.component';
import { CcGeneratorComponent } from './cc-generator/cc-generator.component';
import { AchGeneratorComponent } from './ach-generator/ach-generator.component';
import { StripeCcGeneratorComponent } from './stripe-cc-generator/stripe-cc-generator.component';
import { MainNavbarComponent } from './main-navbar/main-navbar.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { CallDetailsComponent } from './call-details/call-details.component';
import { SmsDetailsComponent } from './sms-details/sms-details.component';
import { ProcessingComponent } from './processing/processing.component';
import { EllipsisAnimationComponent } from './ellipsis-animation/ellipsis-animation.component';
import { PaymentErrorComponent } from './payment-error/payment-error/payment-error.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { BannerComponent } from './banner/banner.component';
import { APPLICATIONS_SERVICE_PROVIDER } from '../services/applications-service/applications.service';
import { AppVersionModule } from './app-version/app-version.module';
import { JiveInputModule } from './input/input.module';
import { AppSwitcherModule } from './app-switcher/app-switcher.module';
import { LanguageSelectorModule } from './language-selector/language-selector.module';
import { MigrationBannerComponent } from './migration-banner/migration-banner-component';

@NgModule( {
  imports: [
    AppMaterialModule,
    CommonModule,
    AppVersionModule,
    RouterModule,
    ErrorsModule,
    OrganizationModule,
    TranslateModule,
    DirectivesModule,
    JiveInputModule,
    AppSwitcherModule,
    LanguageSelectorModule
  ],
  declarations: [
    ContentComponent,
    BannerComponent,
    JiveNavbarRightSideComponent,
    BillingDetailsComponent,
    InvoiceDetailsComponent,
    PaymentMethodsComponent,
    CallDetailsComponent,
    CurrentBalanceComponent,
    NavbarComponent,
    OrganizationSelectorComponent,
    SelectedOrganizationComponent,
    PayTotalComponent,
    PaymentMethodSelectorComponent,
    PaymentMethodGeneratorComponent,
    AutopayComponent,
    ReceiptEmailComponent,
    PaymentConfirmationComponent,
    PayNowComponent,
    PayAmountSelectorComponent,
    PaymentMethodListComponent,
    PbxSelectComponent,
    LanguageSelectorComponent,
    BillingEmailComponent,
    DataErrorComponent,
    CcGeneratorComponent,
    AchGeneratorComponent,
    StripeCcGeneratorComponent,
    MainNavbarComponent,
    InvoiceListComponent,
    ProcessingComponent,
    EllipsisAnimationComponent,
    PaymentErrorComponent,
    ContactInfoComponent,
    SmsDetailsComponent,
    MigrationBannerComponent
  ],
  providers: [ APPLICATIONS_SERVICE_PROVIDER ],
  exports: [ ContentComponent, AppMaterialModule ]
} )
export class ComponentsModule {}
