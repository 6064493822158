import { Injectable } from '@angular/core';
import { MigrationState } from './migration.model';

@Injectable( {
  providedIn: 'root'
} )
export class MigrationActions {

  static readonly GET_MIGRATION_STATE = 'MIGRATION:GET_MIGRATION_STATE';
  static readonly SET_MIGRATION_STATE = 'MIGRATION:SET_MIGRATION_STATE';
  static readonly SET_MIGRATION_STATE_ERROR = 'MIGRATION:ERROR';

  constructor () { }

  getMigrationState ( orgId: string ) {return { type: MigrationActions.GET_MIGRATION_STATE, payload: { orgId } };}

  setMigrationState ( migrationState: MigrationState ) {return { type: MigrationActions.SET_MIGRATION_STATE, payload: { ...migrationState } };}

  setErrorAction ( error: any ) {return { type: MigrationActions.SET_MIGRATION_STATE_ERROR, error: error };}
}
