import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { MIGRATION_STATE_SELECTOR, ORGANIZATION_ID_SELECTOR } from '../../store/helper';
import { MigrationState } from '../../services/migration/redux/migration.model';
import { AppState } from '../../store/app-state.model';
import { MigrationActions } from '../../services/migration/redux/migration.actions';

@Component( {
  selector: 'bp-migration-banner',
  templateUrl: './migration-banner.component.html',
  styleUrls: [ './migration-banner.component.scss' ]
} )
export class MigrationBannerComponent implements OnInit, OnDestroy {
  constructor (
    private reduxStore: NgRedux<AppState>,
    private migrationActions: MigrationActions,
  ) { }

  @select( MIGRATION_STATE_SELECTOR ) migration$: Observable<MigrationState>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;

  unsubscribe$: Subject<boolean> = new Subject();

  showMigrationBanner = false;
  jivePortalSunsetDate: string;

  ngOnInit (): void {
    this.organizationId$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( orgId ) => {
        if ( orgId ) {
          this.reduxStore.dispatch( this.migrationActions.getMigrationState( orgId ) );
        }
      } );

    this.migration$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( migrationState ) => {
        this.showMigrationBanner = migrationState.showMigrationBanner;
        this.jivePortalSunsetDate = migrationState.jivePortalSunsetDate;
      } );
  }

  ngOnDestroy (): void {
    this.unsubscribe$.next( true );
  }
}
