import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MigrationState } from './redux/migration.model';

@Injectable()
export class MigrationService {
  constructor ( private http: HttpClient ) { }

  getGtcMigrationState ( orgId: string ) {
    return this.http.get<MigrationState>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/migrations/gtc-billing` );
  }
}
